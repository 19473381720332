<template>
  <div>
    <div ref="refNewRange" class="range" fluid>
      <b-form-group>
        <b-input
          @blur="addNewRange"
          class="new-range"
          placeholder="Kategorie eingeben"
          v-model="newRangeHeadline"
        ></b-input>
        <b-form-input type="range" class="custom-range" v-model="newRangeValue" min="0" max="10" />
      </b-form-group>
    </div>
    <b-row>
      <b-col v-show="randomButtonId === 1">
        <b-button class="apply-button1 pt-2" @click="toggleNewRange()">
        <b class="">+</b> Eigene Kategorie hinzufügen
        </b-button></b-col
      >
      <b-col v-show="randomButtonId === 2">
        <b-button class="apply-button2 pt-2" @click="toggleNewRange()">
        <b class="">+</b> Eigene Kategorie hinzufügen
        </b-button></b-col
      >
      <b-col cols="1">
        <b-button class="range-save" ref="saveNewRange" @click="addNewRange()"
          ><b-icon class="mt-1" icon="check2-square"></b-icon></b-button></b-col
    ></b-row>
    <div class="new-range-error pt-3" ref="newRangeError">fehler</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      newRangeValue: 5,
      newRangeId: '',
      newRangeHeadline: '',
      rangeValue: '5',
      randomButtonId: 0,
    };
  },
  methods: {
    setRandomButton() {
      const regex = /[a-m]/;
      let lastSessionIdLetter = window.sessionStorage.getItem('upload.Id');
      lastSessionIdLetter = lastSessionIdLetter.substring(lastSessionIdLetter.length, lastSessionIdLetter.length - 1);
      if (regex.test(lastSessionIdLetter)) {
        this.randomButtonId = 1;
      } else {
        this.randomButtonId = 2;
      }
    },
    toggleNewRange() {
      this.$refs.refNewRange.style.display = 'block';
    },
    addNewRange() {
      const regex = /^[a-zA-Z0-9 ,.'-]+$/u;
      if (regex.test(this.newRangeHeadline)) {
        this.newRangeId = this.newRangeHeadline.toLowerCase().split(' ').join('');
        this.$refs.newRangeError.style.display = 'none';
        const arraylength = this.arrayname.length;
        const customRangeId = `custom${arraylength}`;
        const customRangeObject = {
          id: customRangeId,
          rangeheadline: this.newRangeHeadline,
          value: this.newRangeValue,
        };
        this.$refs.refNewRange.style.display = 'none';
        this.newRangeHeadline = '';
        this.$emit('pushNewRange', customRangeObject, this.arrayname);
      }
    },
  },
  // mounted: {
  //   setRandomButton()
  // },
  props: {
    arrayname: Array,
  },
  mounted() {
    this.setRandomButton();
  },
};
</script>
<style scoped>
.apply-button1 {
  color: black !important;
  background-color: #00ff00 !important;
  border-style: solid !important;
  border-width: 2px !important;
  font-weight: 500;
}

.apply-button1:hover {
  color: #00ff00 !important;
  background-color: #2b572e !important;
  border-color: #00ff00 !important;
}
.apply-button2 {
  color: #00ff00 !important;
  background-color: #37563c !important;
  border-color: #00ff00 !important;
  border-style: solid !important;
  border-width: 3px !important;
  font-weight: 500;
}

.apply-button2:hover {
  color: #00ff00 !important;
  background-color: #58815f !important;
  border-color: #00ff00 !important;
}

.new-range-error {
  display: none;
  color: red;
}

.new-range {
  width: 12rem;
  background-color: transparent;
  border: 1px solid #01ff45;
  color: white;
}
.new-range:focus {
  background-color: black !important;
}
input[type='range'] {
  -webkit-appearance: none;
}
.custom-range::-webkit-slider-thumb {
  background-color: #01ff45;
  border: 1px solid #01ff45;
  width: 1rem;
  height: 1rem;
  margin-top: -0.3rem;
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #01ff45;
  border: 1px solid #01ff45;
}
.custom-range::-moz-range-thumb {
  background-color: #01ff45;
  border: 1px solid #01ff45;
  width: 1rem;
  height: 1rem;
}
.custom-range::-moz-range-thumb:active {
  background-color: #01ff45;
  border: 1px solid #01ff45;
}
.custom-range::-ms-thumb {
  background-color: #01ff45;
  border: 1px solid #01ff45;
  width: 1rem;
  height: 1rem;
  margin-top: -0.3rem;
}
.custom-range::-ms-thumb:active {
  background-color: #01ff45;
  border: 1px solid #01ff45;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.3rem;
  color: transparent;
  cursor: pointer;
  background-color: #393838;
  border-color: transparent;
  border-radius: 1rem;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: #535c54;
}

.range {
  margin-top: -1rem;
  display: none;
}
.range-save {
  display: none;
  margin-left: 1rem;
}
</style>
