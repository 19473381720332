<template>
  <b-container fluid class="pb-4 application-container">
    <b-row>
      <b-col cols="12" class="col-md-1"></b-col>
      <b-col cols="12" class="col-md-10 application-stepper">
        <b-row>
          <b-col cols="12" class="application-nav">
            <div id="stepper2" class="bs-stepper">
              <b-col cols="12" class="d-flex justify-content-center bs-stepper-header">
                <div class="step">
                  <b-button @click="setCurrentStep(1)" class="btn step-trigger">
                    <span
                      ref="circle1"
                      style="background-color: #01ff45"
                      class="bs-stepper-circle btn-stepper"
                      >1</span
                    >
                  </b-button>
                </div>
                <div class="line stepper-line"></div>
                <div class="step">
                  <b-button @click="setCurrentStep(2)" class="btn step-trigger">
                    <span ref="circle2" class="bs-stepper-circle btn-stepper">2</span>
                  </b-button>
                </div>
                <div class="line stepper-line"></div>
                <div class="step">
                  <b-button @click="setCurrentStep(3)" class="btn step-trigger">
                    <span ref="circle3" class="bs-stepper-circle btn-stepper">3</span>
                  </b-button>
                </div>
                <div class="line stepper-line"></div>
                <div class="step">
                  <b-button @click="setCurrentStep(4)" class="btn step-trigger">
                    <span ref="circle4" class="bs-stepper-circle btn-stepper">4</span>
                  </b-button>
                </div>
                <div class="line stepper-line"></div>
                <div class="step">
                  <b-button @click="setCurrentStep(5)" class="btn step-trigger">
                    <span ref="circle5" class="bs-stepper-circle btn-stepper">5</span>
                  </b-button>
                </div>
              </b-col>
            </div>
          </b-col>
        </b-row>
        <SurveycontactForm
          ref="surveycontactform"
          @getValues="getValues"
          :stepid="1"
          :current-step="currentStep"
          :headline="'Jetzt bewerben'"
          :text="
            'Bewirb dich jetzt in ein paar Schritten und werde Teil unseres' +
            ' Entwicklerteams. Wir freuen uns auf dich!'
          "
        />
        <RangeComponent
          :stepid="2"
          :current-step="currentStep"
          :headline="'Deine Coding-Skills'"
          :applicationtext="'Trage hier deine Skills für die jeweilige Programmiersprache ein.'"
          :rangevalue="5"
        >
          <div v-for="(item, index) in rangearraycoding" :key="index">
            <RangeElement
              @getValuesStep2="getValuesStep2"
              ref="ranges"
              :id="item.id"
              :rangelabel="item.rangeheadline"
              :arrayname="rangearraycoding"
              @removeCustomRange="removeCustomRange"
            />
          </div>
          <NewRangeelement @pushNewRange="pushNewRange" :arrayname="rangearraycoding" />
        </RangeComponent>
        <RangeComponent
          :stepid="3"
          :current-step="currentStep"
          :headline="'Deine Interessen'"
          :applicationtext="
            'Hier geht es um Deine Interessen. Du kannst gerne auch unabhängig von dem was ' +
            'Du schon kannst, Deine Interessen eintragen. Was möchtest Du gerne machen bzw. lernen?'
          "
          :rangevalue="5"
        >
          <div v-for="(item, index) in rangearrayinterests" :key="index">
            <RangeElement
              @getValuesStep2="getValuesStep2"
              ref="ranges"
              :id="item.id"
              :rangelabel="item.rangeheadline"
              :arrayname="rangearrayinterests"
              @removeCustomRange="removeCustomRange"
            />
          </div>
          <NewRangeelement @pushNewRange="pushNewRange" :arrayname="rangearrayinterests" />
        </RangeComponent>
        <RangeComponent
          :stepid="4"
          :current-step="currentStep"
          :headline="'Was spielst Du?'"
          :applicationtext="
            'Wir wollen dich kennenlernen. Viele Kollegen, die bei uns arbeiten spielen ' +
            'gerne mal eine Runde. Welche Spiele faszinieren Dich?'
          "
          :rangevalue="5"
        >
          <div v-for="(item, index) in rangearraygames" :key="index">
            <RangeElement
              @getValuesStep2="getValuesStep2"
              ref="ranges"
              :id="item.id"
              :rangelabel="item.rangeheadline"
              :arrayname="rangearraygames"
              @removeCustomRange="removeCustomRange"
            />
          </div>
          <NewRangeelement @pushNewRange="pushNewRange" :arrayname="rangearraygames" />
        </RangeComponent>
        <b-row v-show="currentStep === 5" class="stepper-content">
          <UploadWrapper :finalObject="finalObject" @emitData="getDataFromSteps" />
        </b-row>
        <b-col cols="12">
          <b-row>
            <b-col cols="2" class="col-md-3"></b-col>
            <b-col cols="3" class="col-md-3">
              <div
                ref="leftArrow"
                class="pt-3 apply-nav arrow-left"
                @click="backCurrentStep()"
                @KeyDown="backCurrentStep()"
              >
                <span class="h5 pl-2 apply-arrow"><b-icon icon="arrow-left"></b-icon></span>zurück
              </div>
            </b-col>
            <b-col cols="3" class="col-md-auto"></b-col>
            <b-col cols="3" class="col-md-3">
              <div
                ref="rightArrow"
                class="pt-3 pr-3 apply-nav arrow-right"
                @click="nextCurrentStep()"
                @KeyDown="nextCurrentStep()"
              >
                weiter
                <span class="h5 apply-arrow"><b-icon icon="arrow-right"></b-icon></span>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-col>
    </b-row>
  </b-container>
</template>
<style>
#fileupload {
  color: white;
}
.application-container {
  background-image: url('../../assets/img/jobadbg.png');
}
#application-arrows-left {
  display: none;
}

.upload-text {
  color: #6c757d;
  font-weight: 700;
  font-size: 1.2rem;
}
#stepper-1 {
  display: flex;
}

#stepper-2 {
  display: none;
}

#stepper-3 {
  display: none;
}

#stepper-4 {
  display: none;
}

#stepper-5 {
  display: none;
}

#circle1 {
  background-color: #01ff45;
}

.application-nav {
  height: 3rem;
}

.application-stepper {
  min-height: 30rem;
  background-color: #151616;
  margin-top: -5rem !important;
  border-radius: 15px;
}
.arrow-left {
  display: none;
}
.application-text {
  color: white;
  text-align: left;
}

.application-text-headline {
  font-weight: 600;
  font-size: 1.6rem;
  color: white;
}

.stepper-line {
  background-color: #01ff45 !important;
  height: 0.2rem;
}

.apply-input {
  background-color: #151616 !important;
  color: white !important;
  border: 2px solid white !important;
}

.apply-input-label {
  position: absolute;
  top: 0.1rem;
  left: 1.9rem;
  background-color: #151616;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  font-family: 'Montserrat';
  font-size: 0.9rem;
}

.apply-arrow {
  color: #01ff45;
}

.apply-nav {
  color: white;
}

.apply-nav:hover {
  cursor: pointer;
}

.application-upload {
  border: 0.15rem dashed #a5a5c7;
  height: 12rem;
}

.application-uploaded {
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-color: #0ccd0e;
}

.custom-file-label {
  width: 16.5rem !important;
  height: 10rem !important;
  opacity: 0;
  cursor: pointer;
}

.apply-button {
  color: #00ff00 !important;
  background-color: #37563c !important;
  border-color: #00ff00 !important;
  border-style: solid !important;
  border-width: 2px !important;
  border-radius: 25px !important;
  height: 1rem !important;
}

.apply-button:hover {
  color: #00ff00 !important;
  background-color: #58815f !important;
  border-color: #00ff00 !important;
}

.dz-upload {
  margin-top: 3rem !important;
  background-color: #00ff00 !important;
}

@media (max-width: 720px) {
  .stepper-line {
    display: none;
  }
}
</style>
<script>
import RangeComponent from '@/components/tools/RangeComponent.vue';
import RangeElement from '@/components/tools/RangeElement.vue';
import NewRangeelement from '@/components/tools/NewRangeelement.vue';
import UploadWrapper from '@/components/tools/UploadWrapper.vue';
import SurveycontactForm from '@/components/tools/SurveycontactForm.vue';

export default {
  data() {
    return {
      emailResponse: '',
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      currentStep: 1,
      uploadSession: '',
      rangearraycoding: [
        { id: 'jsskill', rangeheadline: 'Javascript', value: 5 },
        { id: 'tsskill', rangeheadline: 'Typescript', value: 5 },
        { id: 'htmlskill', rangeheadline: 'HTML', value: 5 },
        { id: 'cssskill', rangeheadline: 'CSS', value: 5 },
        { id: 'phpskill', rangeheadline: 'PHP', value: 5 },
        { id: 'phytonskill', rangeheadline: 'Phyton', value: 5 },
        { id: 'javaskill', rangeheadline: 'Java', value: 5 },
        { id: 'sqlskill', rangeheadline: 'SQL', value: 5 },
      ],
      rangearrayinterests: [
        { id: 'frontendskill', rangeheadline: 'Frontend', value: 5 },
        { id: 'backendskill', rangeheadline: 'Backend', value: 5 },
        { id: 'uiskill', rangeheadline: 'UI/UX-Design', value: 5 },
        { id: 'dbskill', rangeheadline: 'Datenbanken', value: 5 },
      ],
      rangearraygames: [
        { id: 'csskill', rangeheadline: 'Counter-Strike', value: 5 },
        { id: 'cdskill', rangeheadline: 'Call of Duty', value: 5 },
        { id: 'rlskill', rangeheadline: 'Rocket League', value: 5 },
        { id: 'aoeskill', rangeheadline: 'Age of Empires', value: 5 },
        { id: 'fifaskill', rangeheadline: 'FIFA', value: 5 },
      ],
      finalObject: {},
    };
  },
  components: {
    RangeComponent,
    RangeElement,
    UploadWrapper,
    NewRangeelement,
    SurveycontactForm,
  },
  methods: {
    removeCustomRange(customIndex, ArrayName) {
      const deleteIndex = ArrayName.findIndex((item) => item.id === customIndex);
      ArrayName.splice(deleteIndex, 1);
    },
    pushNewRange(newObject, ArrayName) {
      ArrayName.push(newObject);
      this.rangearraygames = ArrayName;
    },
    getValues(data) {
      this.firstname = data.firstname;
      this.lastname = data.lastname;
      this.email = data.email;
      this.phone = data.phone;
    },
    getValuesStep2(data) {
      const rangeArray = [
        ...this.rangearraycoding,
        ...this.rangearrayinterests,
        ...this.rangearraygames,
      ];
      const rangedResponse = rangeArray.find((skill) => skill.id === data.id);
      rangedResponse.value = data.rangeValue;
    },
    setCurrentStep(step) {
      this.currentStep = step;
      for (let i = 1; i <= 5; i += 1) {
        if (i === step) {
          this.$refs[`circle${i}`].style.backgroundColor = '#01ff45';
        } else {
          this.$refs[`circle${i}`].style.backgroundColor = '#6c757d';
        }
      }
      if (this.currentStep === 1) {
        this.$refs.leftArrow.style.display = 'none';
      } else {
        this.$refs.leftArrow.style.display = 'flex';
      }
      if (this.currentStep === 5) {
        this.$refs.rightArrow.style.display = 'none';
      } else {
        this.$refs.rightArrow.style.display = 'flex';
      }
    },
    nextCurrentStep() {
      if (this.currentStep < 5) {
        this.setCurrentStep(this.currentStep + 1);
      }
    },
    backCurrentStep() {
      if (this.currentStep > 1) {
        this.setCurrentStep(this.currentStep - 1);
      }
    },
    getDataFromSteps() {
      this.$refs.surveycontactform.sendData();
      this.$refs.ranges.forEach((element) => {
        element.sendData();
      });
      this.uploadSession = window.sessionStorage.getItem('upload.Id');
      const InfoObject = {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        phone: this.phone,
        uploadId: 'nofile',
      };
      const CodingObject = {};
      for (let i = 0; i < this.rangearraycoding.length; i += 1) {
        const item = this.rangearraycoding[i];
        CodingObject[i] = { headline: item.rangeheadline, value: item.value };
      }
      const InterestObject = {};
      for (let i = 0; i < this.rangearrayinterests.length; i += 1) {
        const item = this.rangearrayinterests[i];
        InterestObject[i] = { headline: item.rangeheadline, value: item.value };
      }
      const GamesObject = {};
      for (let i = 0; i < this.rangearraygames.length; i += 1) {
        const item = this.rangearraygames[i];
        GamesObject[i] = { headline: item.rangeheadline, value: item.value };
      }
      this.finalObject.value = {
        title: 'Webentwickler',
        info: InfoObject,
        coding: CodingObject,
        interest: InterestObject,
        games: GamesObject,
      };
    },
  },
};
</script>
