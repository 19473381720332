<template>
    <b-container fluid class="jobad-benefits-container pt-4 flex-wrap">
      <b-row class="mb-3" >
        <b-col cols="12" class="d-flex justify-content-center">
          <h2 class="text-center text-md-left mb-5 h2-media-size jobad-benefits-headline">
            Was dich erwartet</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="col-xxl-2 col-xl-2"></b-col>
      <b-col cols="12" class="col-xxl-8 col-xl-8">
        <b-row>
        <b-col cols="12" class="offset-lg-0 col-lg-4 col-sm-8
            offset-sm-2 col-md-4 offset-md-3 pt-2">
              <KercardBlack
              :CardIconPath="require('../../assets/img/jobad_benefits_left.svg')"
              :CardHeadline1="'Dynamisches &'"
              :CardHeadline2="'junges Team'"
                :CardText="
            'Bei uns wird Teamarbeit großgeschrieben. Bei Fragen kannst du jederzeit auf die' +
            ' Expertise der Kollegen zurückgreifen. Flache Hierarchien, kurze Entscheidungswege.'"
              />
            </b-col>
            <b-col cols="12" class="offset-lg-0 col-lg-4 col-sm-8
            offset-sm-2 col-md-4 offset-md-3 pt-2">
              <KercardBlack
              :CardIconPath="require('../../assets/img/jobad_benefits_center.svg')"
              :CardHeadline1="'Flexibilität &'"
              :CardHeadline2="'Freiraum'"
                :CardText="
                  'Wenn die Arbeit es zulässt, kannst du Arbeitsort und Zeit frei wählen. Da' +
                  ' unser Unternehmen aktuell stark wächst, kannst du vieles mitgestalten.'"
              />
            </b-col>
            <b-col cols="12" class="offset-lg-0 col-lg-4 col-sm-8
            offset-sm-2 col-md-4 offset-md-3 pt-2">
              <KercardBlack
              :CardIconPath="require('../../assets/img/jobad_benefits_right.svg')"
              :CardHeadline1="'Spaß nehmen'"
              :CardHeadline2="'wir ernst'"
                :CardText="
                  'Für eine kompetitive Runde an der Playstation ist immer jemand zu haben.' +
                  ' Wenn du Lust hast, kannst du auch bei firmeninternen Netzwerkpartys dein ' +
                  ' Können unter Beweis stellen.'"
              />
            </b-col>
        </b-row>
            </b-col>
          </b-row>
    </b-container>
  </template>
<script>
import KercardBlack from '@/components/tools/KercardBlack.vue';

export default {
  components: {
    KercardBlack,
  },
};
</script>
<style scoped>
.jobad-benefits-container {
    background: url('@/assets/img/jobad_benefits.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    min-height:40rem;
    background-color: #101010;
}
.jobad-benefits-headline {
    font-weight: 700;
    color:white;
}
@media (max-width:1200px) {
  .jobad-benefits-container {
    background-image: none;
  }
}
</style>
