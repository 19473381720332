<template>
  <div>
    <div class="elfsight-app-953ca96e-adb9-452d-a5c0-6c57354642ba widget"></div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.addElfsightScript();
  },
  methods: {
    addElfsightScript() {
      const elfsightScript = document.createElement('script');
      elfsightScript.setAttribute('src', 'https://static.elfsight.com/platform/platform.js');
      elfsightScript.setAttribute('data-use-service-core', '');
      elfsightScript.defer = true;
      document.head.appendChild(elfsightScript);
    },
  },
};
</script>
<style scoped>

.widget {
  max-width: 1440px;
  margin: auto;
  margin-top: 5rem;
}
</style>
