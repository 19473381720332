<template>
  <b-container fluid>
    <b-col cols="12" class="pt-4 col-md-10 offset-md-1">
      <b-row>
        <b-col cols="12" class="col-md-2"></b-col>
        <b-col cols="12" class="pt-3 col-md-8">
          <h3 class="pt-3 application-text-headline">{{ headline }}</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="col-md-2"></b-col>
        <b-col
          cols="12"
          class="pt-3 col-md-8 application-text d-flex align-content-center justify-content-center"
        >
          <p>
            {{ text }}
          </p>
        </b-col>
      </b-row>
      <b-row class="pt-3">
        <b-col cols="12" class="col-md-1"></b-col>
        <b-col cols="12" class="col-md-10">
          <UploaderComponent ref="uploadercomponent" @getApplicationFiles="getApplicationFiles" />
        </b-col>
      </b-row>
    </b-col>
    <b-row class="alert-wrapper">
      <b-row v-show="emailResponse"
        ><b-col>
          <div class="alert alert-successful" role="alert">
            <b-icon icon="check-circle"></b-icon> {{ emailResponse }}
          </div>
        </b-col>
      </b-row>
      <b-row v-show="missingDataResponse"
        ><b-col>
          <div class="alert alert-error" role="alert">
            <b-icon icon="x-circle"></b-icon> {{ missingDataResponse }}
          </div>
        </b-col>
      </b-row>
    </b-row>

    <b-row>
      <b-col cols="12" class="col-md-3"></b-col>
      <b-col cols="12" class="col-md-6 justify-content-center">
        <b-row
          ><b-col
            ><b-button ref="submitButton" class="apply-button pt-3" @click="submitApplication()"
              >Bewerbung einreichen
              <b-spinner
                ref="submitSpinner"
                class="applySpinner ml-2"
              ></b-spinner> </b-button></b-col
        ></b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import UploaderComponent from '@/components/tools/UploaderComponent.vue';

const axios = require('axios');

export default {
  components: {
    UploaderComponent,
  },
  data() {
    return {
      showMaxFileSizeError: false,
      missingDataResponse: '',
      newObject: this.finalObject,
      emailResponse: '',
      headline: 'Bewerbung abschicken',
      text:
        'Hier kannst Du jetzt noch per PDF Deine Unterlagen einreichen. Dazu gehören am' +
        ' Besten: Lebenslauf, Zeugnisse und gerne auch ein kurzes Anschreiben. Über' +
        ' "Bewerbung einreichen" lässt Du uns dann alles zukommen und wir melden uns bei Dir.',
      randomButtonName: '',
      apiToken: '825f447d-be86-4480-9225-21c238569954',
      files: [],
    };
  },
  props: {
    finalObject: Object,

    path: {
      type: String,
      default: 'new-application',
    },
  },
  methods: {
    getApplicationFiles(file) {
      console.log(file.upload.total);
      if (file.upload.total < 5262786) {
        this.files.push(file);
      } else {
        this.missingDataResponse = 'Die maximale Dateigröße einer Datei wurde überschritten.';
      }
    },
    sendApplicantData() {
      const today = new Date();
      const message = JSON.stringify(this.newObject.value);
      const headerData = {
        headers: {
          'Api-Token': this.apiToken,
          'Content-Type': 'multipart/form-data',
        },
      };
      const formDataHost = new FormData();
      formDataHost.append(
        'recipients',
        JSON.stringify({
          email: 'kontakt@keravonos.com',
          name: 'Keravonos Team',
        }),
      );
      formDataHost.append('sender', 'kontakt@keravonos.com');
      formDataHost.append('sender_name', 'Bewerbungsformular');
      formDataHost.append('subject', `Bewerbung Keravonos Website - ${today.toLocaleDateString()}`);
      formDataHost.append('text', message);
      for (let i = 0; i < this.files.length; i += 1) {
        formDataHost.append('attachments', this.files[i]);
      }
      formDataHost.append(
        'html',
        `
        <h1>Bewerbungsformular wurde ausgefüllt von ${this.newObject.value.info.firstname}
          ${this.newObject.value.info.lastname}</h1>
        <p>Folgendes stand in dem Bewerbungsformular: </p>
        <p>${message}</p>
        <p>E-Mail Adresse: ${this.newObject.value.info.email}</p>
        `,
      );
      axios
        .post('https://mail.keravonos.com/send_email', formDataHost, headerData)
        .then((response) => {
          if (response.status === 202) {
            this.$refs.submitSpinner.style.display = 'none';
            this.emailResponse = 'Bewerbung wurde erfolgreich abgeschickt!';
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    setRandomButton() {
      const regex = /[a-m]/;
      let lastSessionIdLetter = window.sessionStorage.getItem('upload.Id');
      if (lastSessionIdLetter) {
        lastSessionIdLetter = lastSessionIdLetter.substring(
          lastSessionIdLetter.length,
          lastSessionIdLetter.length - 1,
        );
        if (regex.test(lastSessionIdLetter)) {
          this.randomButtonName = 'neon';
        } else {
          this.randomButtonName = 'olive';
        }
      } else {
        this.randomButtonName = 'neon';
      }
    },
    submitApplication() {
      this.missingDataResponse = '';
      this.$refs.submitButton.disabled = true;
      this.$refs.submitSpinner.style.display = 'inline-block';
      this.$emit('emitData');
      this.newObject.value.info.uploadId = window.sessionStorage.getItem('upload.Id');
      this.setRandomButton();
      this.newObject.value.info.randomButtonName = this.randomButtonName;
      console.log(this.newObject.value);
      if (
        this.newObject.value.info.firstname === '' ||
        this.newObject.value.info.email === '' ||
        this.newObject.value.info.lastname === '' ||
        this.newObject.value.info.phone === ''
      ) {
        this.$refs.submitButton.disabled = false;
        this.$refs.submitSpinner.style.display = 'none';
        this.missingDataResponse = 'Bitte gib deine Kontaktdaten an!';
        return;
      }
      this.sendApplicantData();
    },
  },
};
</script>
<style scoped>
.applySpinner {
  display: none;
  color: #00ff00;
  width: 1.1rem;
  height: 1.1rem;
}

.alert-successful {
  background-color: none !important;
  color: #00ff00;
  transition: color 0.5s ease;
  margin-left: 20px;
  padding: 0;
}
.alert-error {
  background-color: none !important;
  color: red;
  margin-left: 20px;
  transition: color 0.5s ease;
  padding: 0;
}

.alert-wrapper {
  height: 50px;
}
</style>
