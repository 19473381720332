<!--
JobadHeader
JobadTasks
JobaddBenefits
JobaddQuote
JobadApplication
-->
<template>
  <div class="webdev">
    <IntroComponent
      :IntroHeadline="'Junior Consultant für Prozessoptimierung und Digitalisierung'"
      :IntroBg="require('../assets/img/jucon_cut_longer.png')"
      :IntroText="
        'In direkter Zusammenarbeit mit unserem Geschäftsführer übernimmst du ab dem' +
        ' ersten Tag Verantwortung und gestaltest die Zukunft von KERAVONOS maßgeblich mit.'
      "
      :IntroButton="'Jetzt bewerben'"
      :IntroButtonType="'Intro-Button-2'"
    />
    <JobadTasks :tasks="tasks" :profiles="profiles" />
    <JobadBenefits />
    <JobadQuote
      :quoteImage="require('../assets/img/profil_rainer.png')"
      :quoteText="
        '„Ich freue mich riesig darauf, mit dir gemeinsam Prozesse' +
        ' zu verbessern und digitale Lösungen zu gestalten!“'
      "
      :quoteName="'Rainer'"
      :quoteDate="'Gründer'"
    />
    <ApplicationJucon />
  </div>
</template>
<script>
import IntroComponent from '@/components/tools/IntroComponent.vue';
import JobadTasks from '@/components/tools/JobadTasks.vue';
import JobadBenefits from '@/components/tools/JobadBenefits.vue';
import JobadQuote from '@/components/tools/JobadQuote.vue';
import ApplicationJucon from '@/components/tools/ApplicationJucon.vue';

export default {
  components: {
    IntroComponent,
    JobadTasks,
    JobadBenefits,
    JobadQuote,
    ApplicationJucon,
  },
  data() {
    return {
      tasks: [
        {
          task:
            'Du wirst hauptsächlich Beratungsprojekte im Kontext der Digitalisierung bearbeiten.' +
            ' Da wir ein junges Unternehmen im Wachstum sind, kannst du dich aktiv mit in die Gestaltung' +
            ' unserer Projekte einbringen. Neben der Aufnahme, Optimierung und Digitalisierung von' +
            ' Prozessen hast du auch die Möglichkeit bei der Konzeption neuer Softwarelösungen mitzuwirken' +
            ' und diese bei unseren Kunden zu implementieren.',
        },
      ],
      profiles: [
        {
          profile: 'Erfolgreich abgeschlossenes Hochschulstudium',
        },
        {
          profile:
            'Erste Berufserfahrung im Rahmen von Praktika oder Werkstudententätigkeit,' +
            ' am Besten in der Unternehmensberatung',
        },
        {
          profile:
            'Idealerweise erste Praxiserfahrung in der Projektarbeit mit Schwerpunkt' +
            ' Projektmanagement, Moderation und Change Management',
        },
        {
          profile: 'Ausgeprägte IT-Affinität und Neugier für neue Technologien & Methoden',
        },
        {
          profile: 'Interesse an der direkten Beratung auf Abteilungs- wie Geschäftsleitungsebene',
        },
        {
          profile: 'Agiles Mindset, Empathie- & Kommunikationsfähigkeit sowie ein ausgeprägter Teamgeist',
        },
        {
          profile: 'Verhandlungssichere Deutsch- und Englischkenntnisse und die nötige Reisebereitschaft.',
        },
      ],
    };
  },
};
</script>
