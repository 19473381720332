<!--
JobadHeader
JobadTasks
JobaddBenefits
JobaddQuote
JobadApplication
-->
<template>
  <div>
    <IntroComponent
      :IntroHeadline="'Initiativbewerbung'"
      :IntroBg="require('../assets/img/3dbuttongreen_s.png')"
      :IntroText="'Du würdest gerne unser Team verstärken? Wenn dein Profil nicht von einer der\
      ausgeschriebenen Stellen abgedeckt wird, bewirb dich gerne hier!'"
      :IntroButton="'Jetzt bewerben'"
      :IntroButtonType="'Intro-Button-2'"
    />
    <JobaddBenefits></JobaddBenefits>
    <div style="height: 8rem; background-color: #101010"></div>
    <ApplicationInitiative />
  </div>
</template>
<script>
import IntroComponent from '@/components/tools/IntroComponent.vue';
import ApplicationInitiative from '@/components/tools/ApplicationInitiative.vue';
import JobaddBenefits from '@/components/tools/JobadBenefits.vue';

export default {
  components: {
    IntroComponent,
    ApplicationInitiative,
    JobaddBenefits,
  },
};
</script>
