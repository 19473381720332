<template>
  <!--  eslint-disable -->
  <b-container
  @click="$bvModal.show(ModalID)" 
    cols="12"
    :class="[
      ModalID !== '' ? 'ref-img' : '',
      'col-md-4',
      'd-flex',
      'justify-content-center',
      'align-items-center',
      'block'
    ]"
    :style="
      'background: linear-gradient(1deg, rgba(0,0,0,0.8) 0%, rgba(4,4,4,0.9) 54%, rgba(0,0,0,0.3) 100%), url(' +
      BlockBgPath +
      ')'
    "
  >
    <b-col cols="12">
      <b-row>
        <b-col cols="12" class="col-md-1"></b-col>
        <b-col cols="12" class="col-md-10">
          <img alt="" :src="BlockImage"/>
        </b-col>
        <b-col cols="12" class="block-caption mt-3">
          {{ BlockCaption }}
        </b-col>
      </b-row>
    </b-col>
  </b-container>
</template>
<script>

export default {
  props: {
    ModalID: String,
    BlockLink: String,
    BlockBgPath: String,
    BlockImage: String,
    BlockCaption: String,
    BlockHeadline: String,
  },
};
</script>
<style scoped>
.block {
  height: 60rem;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.ref-img:hover {
cursor: pointer;
}
.block-image {
  width: 100%;
  padding: 0.5rem;
}
.block-headline {
  color: white;
  font-size: 6.5rem;
  font-family: Montserrat;
  font-weight: 700;
}
.block-caption {
  color: white;
  font-family: Montserrat;
  font-size: 0.9rem;
}
</style>
