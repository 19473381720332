import { render, staticRenderFns } from "./JobadQuote.vue?vue&type=template&id=8e4b994a&scoped=true&"
import script from "./JobadQuote.vue?vue&type=script&lang=js&"
export * from "./JobadQuote.vue?vue&type=script&lang=js&"
import style0 from "./JobadQuote.vue?vue&type=style&index=0&id=8e4b994a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e4b994a",
  null
  
)

export default component.exports