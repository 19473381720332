/* eslint-disable */
export function getGtagJs() {
  const customConfig = {
    headers: {
      'Content-Type': 'application/javascript',
    },
  };

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag('js', new Date());

  gtag('config', 'G-0S5661WLT8');
}
