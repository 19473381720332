<template>
  <b-col cols="12" class="col-lg-6 pt-4">
    <b-card class="modalcard">
      <b-row>
        <b-col cols="12" class="col-lg-4"></b-col>
        <b-col cols="12" class="col-lg-4 p-2 d-flex justify-content-center">
          <img alt="?" class="modalcard-image mt-2" :src="ModalImgSrc" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="col-lg-1"></b-col>
        <b-col cols="12" class="col-lg-10 mt-1">
        <div class="modal-headline d-flex justify-content-center align-items-">
          <h4>{{ModalHeadline1}}</h4>
        </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="auto" class="col-lg-1"></b-col>
        <b-col cols="12" class="col-lg-10">
          <p class="kermodal-text">
        {{ ModalText }}
          </p>
        </b-col>
      </b-row>
    </b-card>
  </b-col>
</template>
<script>
export default {
  props: {
    ModalImgSrc: String,
    ModalHeadline1: String,
    ModalHeadline2: String,
    ModalText: String,
  },
};
</script>
<style scoped>
.modalcard {
  font-family: Montserrat;
   height:24rem !important;
   border-radius: 15px !important;
   box-shadow: 0px 0px 10px 10px rgba(33,37,41,0.08);
}
h4 {
  font-weight: 700;
  font-size: 1.2rem;
}
.modalcard-image {
    width:75%;
    height:5.5rem;
}
.modal-headline {
  text-align: center;
  height:3rem !important;
}
.kermodal-text {
   font-family: Montserrat;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   text-align: left;
   font-size: 1rem;
   color: black;
   line-height: 1.2rem;
}
@media (max-width: 768px) {
  .modal-dialog {
    width: 90% !important;
    margin: 0 auto !important;
  }
  .modalcard {
   height:100% !important;
}
.modal-headline {
  height:4rem !important;
}
}
</style>
