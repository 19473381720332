<template>
  <b-card class="kercard" v-on="$listeners">
    <b-row class="d-flex flex-column flex-lg-row">
      <b-col cols="12" class="col-12 col-lg-7 d-flex relative">
        <div class="w-100 h-100 ker-x-asana-image"></div>
      </b-col>
      <b-col cols="12" class="col-12 col-lg-5 mt-2 d-flex flex-column">
        <img
          alt="?"
          class="ker-asana-badge py-2 mr-lg-4"
          :src="require('../../assets/img/asana_partners_solutions_badge_secondary.svg')"
        />
        <div class="asana-card-text-content p-5 mt-2">
          <h4 class="title-height" v-html="CardHeadline"></h4>
          <ul class="x-list">
            <li>Digitalisierung & Optimierung der unternehmensinternen Arbeitsprozesse</li>
            <li>Restrukturierung der Softwarelandschaft</li>
            <li>Einführung von Asana durch Schulungen, Workshops und <b>Change Management</b></li>
          </ul>

          <router-link class="navbar-header-link" :to="{ path: CardButtonLink, hash: CardHash }">
            <b-button class="button-default mt-4 mb-3">
              {{ CardButtonText }}
            </b-button></router-link
          >
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
export default {
  props: {
    CardHash: String,
    CardHeadline: String,
    CardButtonText: String,
    CardButtonLink: String,
  },
};
</script>
<style scoped>
.title-height {
  line-height: 2rem;
  margin-bottom: 1.5rem;
}
.modal-button {
  color: #fff;
  background-color: #37563c !important;
  border-color: #37563c !important;
  border-style: none !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  font-size: 1rem !important;
  border-radius: 3rem !important;
}
.kercard {
  min-height: 16rem;
  overflow: hidden;
}
.card-body {
  padding: 0 !important;
}
.kercard-text {
  height: 9rem;
  text-overflow: ellipsis;
  overflow: hidden;
}
.kercard-image {
  width: 100%;
}
.x-list {
  padding-left: 0;
}

.ker-x-asana-image {
  background-image: url('../../assets/img/KER-ASA-Image.png');
  background-color: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 15rem;
}

.ker-asana-badge {
  max-width: 14rem;
}

.x-list li {
  background-image: url('../../assets/img/X-list-icon.svg');
  background-repeat: no-repeat;
  background-position: 0rem 0.3rem;
  list-style: none;
  text-align: left;
  padding-left: 2rem;
  padding-top: 0rem;
  margin-bottom: 0.8rem;
  margin-top: 0;
  line-height: 1.67;
}

h4 {
  font-weight: 700;
}
p {
  line-height: 1.8rem;
}

.ker-asana-badge {
  align-self: center;
}

@media screen and (min-width: 1080px) {
  .asana-card-text-content {
    text-align: left;
  }

  .ker-asana-badge {
    align-self: flex-end;
  }
}
</style>
