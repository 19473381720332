<template>
  <b-row>
<b-col>
  <b-form-group class="range">

  <p class="d-flex"><span class="pt-2">{{ rangelabel + ':' }}</span>
    <input @click="getIndex()"
    v-show="id.substring(0,6) === 'custom'" type="submit" value="X"
    class="removeRangeButton"/>
  </p>
    <b-form-input
      v-model="rangeValue"
      :id="id"
      type="range"
      class="custom-range"
      min="0"
      max="10"
    />
  </b-form-group></b-col>
  </b-row>
</template>
<script>
export default {
  data() {
    return {
      rangeValue: '5',
    };
  },
  props: {
    rangelabel: String,
    id: String,
    index: Number,
    arrayname: Array,
  },
  methods: {
    sendData() {
      const data = {
        rangeValue: this.rangeValue,
        id: this.id,
      };
      this.$emit('getValuesStep2', data);
    },
    getIndex() {
      const customIndex = this.id;
      this.$emit('removeCustomRange', customIndex, this.arrayname);
    },
  },
};
</script>
<style scoped>
.removeRangeButton {
  color:red;
  background: none;
  border: none;
  font-size:13px;
  font-weight:700;
}

input[type='range'] {
  -webkit-appearance: none;
}
.custom-range::-webkit-slider-thumb {
  background-color: #01ff45;
  border: 1px solid #01ff45;
  width: 1rem;
  height: 1rem;
  margin-top: -0.3rem;
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #01ff45;
  border: 1px solid #01ff45;
}
.custom-range::-moz-range-thumb {
  background-color: #01ff45;
  border: 1px solid #01ff45;
  width: 1rem;
  height: 1rem;
}
.custom-range::-moz-range-thumb:active {
  background-color: #01ff45;
  border: 1px solid #01ff45;
}
.custom-range::-ms-thumb {
  background-color: #01ff45;
  border: 1px solid #01ff45;
  width: 1rem;
  height: 1rem;
  margin-top: -0.3rem;
}
.custom-range::-ms-thumb:active {
  background-color: #01ff45;
  border: 1px solid #01ff45;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.3rem;
  color: transparent;
  cursor: pointer;
  background-color: #393838;
  border-color: transparent;
  border-radius: 1rem;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: #535c54;
}

.range {
  margin-top: -1rem;
}
</style>
