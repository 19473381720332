<template>
    <b-container fluid class="references-container">
        <b-row id="testid">
        <ReferencesBlock
        :ModalID="''"
        :BlockLink="'https://rentg8.de/'"
        :BlockCaption="'Onlineverleihsystem für Bikes'"
        :BlockImage="require('../assets/img/rentgate_box.svg')"
        :BlockBgPath="require('../assets/img/references_rentgate.png')"
        />
        <ReferencesBlock
        :ModalID="''"
        :BlockLink="'https://www.fshh.io/'"
        :BlockCaption="'Überregionale Vermietung von E-Bikes'"
        :BlockImage="require('../assets/img/fshh_box.svg')"
        :BlockBgPath="require('../assets/img/references_fshh.png')"
        />
        <ReferencesBlock
        :ModalID="'abusmodal'"
        :BlockLink="'https://www.abus-kransysteme.de/'"
        :BlockCaption="'Abus Kransysteme'"
        :BlockImage="require('../assets/img/abus_box.svg')"
        :BlockBgPath="require('../assets/img/references_abus.png')"
        />
    </b-row>
    <AbusModal />
    </b-container>
</template>
<script>
import AbusModal from '@/components/tools/AbusModal.vue';
import ReferencesBlock from '@/components/tools/ReferencesBlock.vue';

export default {
  components: {
    ReferencesBlock,
    AbusModal,
  },
};
</script>
<style>
.references-container {
background-color: black;
}
</style>
