import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import vue2Dropzone from 'vue2-dropzone';
import Vue from 'vue';
import CookieConsent from 'vue-cookieconsent-component';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/css/ke_style.css';
import './assets/css/bootstrap.min.css';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(vue2Dropzone);
Vue.use(CookieConsent);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
