<!--
JobadHeader
JobadTasks
JobaddBenefits
JobaddQuote
JobadApplication
-->
<template>
  <div class="webdev">
    <IntroComponent
      :IntroHeadline="'Webentwickler'"
      :IntroBg="require('../assets/img/all_in_the_office_cut_darker.png')"
      :IntroText="
        'Mit der Unterstützung des Teams entwickelst du moderne Webanwendungen.' +
        ' Als KERAVONOS entwickeln wir unsere Buchungssoftware weiter, automatisieren Workflows' +
        ' und implementieren beständig neue Web-Apps bei unseren Kunden.'
      "
      :IntroButton="'Jetzt bewerben'"
      :IntroButtonType="'Intro-Button-2'"
    />
    <JobadTasks :tasks="tasks" :profiles="profiles" />
    <JobadBenefits />
    <JobadQuote
      :quoteImage="require('../assets/img/tim_potrait_round.png')"
      :quoteText="
        '„Nach dem Studium habe ich direkt Projektverantwortung bekommen.' +
        ' Ich habe im ersten Jahr eine super Lernkurve gehabt. Besonders weil ich, wenn ich Fragen' +
        ' hatte, immer eine ausführliche Erklärung bekommen habe.“'
      "
      :quoteName="'Tim'"
      :quoteDate="'seit 2020 im Team'"
    />
    <ApplicationWebdev />
  </div>
</template>
<script>
import IntroComponent from '@/components/tools/IntroComponent.vue';
import JobadTasks from '@/components/tools/JobadTasks.vue';
import JobadBenefits from '@/components/tools/JobadBenefits.vue';
import JobadQuote from '@/components/tools/JobadQuote.vue';
import ApplicationWebdev from '@/components/tools/ApplicationWebdev.vue';

export default {
  components: {
    IntroComponent,
    JobadTasks,
    JobadBenefits,
    JobadQuote,
    ApplicationWebdev,
  },
  data() {
    return {
      tasks: [
        {
          task:
            'Je nach deinem persönlichen Schwerpunkt kannst du im gesamten' +
            ' Stack vom Frontend bis zum Backend entwickeln',
        },
        {
          task:
            'Nutzung und Weiterentwicklung deiner persönlichen technischen Expertise von' +
            ' Support bis hin zur kompletten Umsetzungsverantwortung',
        },
        { task: 'Beständiges up to date halten über technische Neuerungen in der Webentwicklung.' },
      ],
      profiles: [
        { profile: 'Grundlegende Programmierkenntnisse im modernen Webstack' },
        { profile: 'Begeisterung für technische Lösungen und eigenverantwortliches Arbeiten.' },
        { profile: 'Erste Erfahrungen mit Backendtechnologien' },
        { profile: 'Abgeschlossenes Studium im IT-Bereich oder einer fachlich passenden Ausbildung.' },
        { profile: 'Agiles Mindset sowie ein ausgeprägter Teamgeist' },
        { profile: 'Einwandfreie Deutsch- und Englischkenntnisse' },
      ],
    };
  },
};
</script>
