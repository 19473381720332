<template>
  <b-modal id="abusmodal" class="kermodal" size="xl" hide-footer scrollable centered>
    <template #modal-header>
      <div style="width: 100%; margin-top: -1.3rem">
        <b-row>
          <b-col cols="12" class="col-md-1"></b-col>
          <b-col cols="10" class="col-md-10 pt-3">
            <h3>Prozessoptimierung der Personalabteilung <br />durch innovative Software</h3>
          </b-col>
          <b-col cols="1" class="col-md-1 d-flex float-right">
            <b-icon
              class="h2 modal-close"
              @click="$bvModal.hide('abusmodal')"
              icon="x-circle"
            ></b-icon>
          </b-col>
        </b-row>
      </div>
    </template>
    <b-container fluid>
      <b-row>
        <ModalCard
          :ModalImgSrc="require('@/assets/img/goal.svg')"
          :ModalHeadline1="'Ziel des Projekts'"
          :ModalText="
            'Das Ziel des Projekts war es, die Prozesse in der' +
            ' Personalabteilung durch Reorganisation der Softwarelandschaft hinsichtlich' +
            ' auf Sicherheit, Redundanz und Effizienz zu optimieren.'
          "
        />
        <ModalCard
          :ModalImgSrc="require('@/assets/img/handshake.svg')"
          :ModalHeadline1="'Kunde und Herausforderungen'"
          :ModalText="
            'Unser langjähriger Geschäftspartner ist die angesehene Frima ABUS Kransysteme. ' +
            'Die besondere Herausforderung in diesem Projekt war, bei einer dünnen Personaldecke ausreichend ' +
            'Zeit bei den Beteiligten zu finden, um Raum für Lösungen zu schaffen!'
          "
        />
        <ModalCard
          :ModalImgSrc="require('@/assets/img/planning.svg')"
          :ModalHeadline1="'Lösungsansatz'"
          :ModalText="
            'Unser Ansatz bestand darin, die bestehenden Prozesse im Detail zu' +
            ' analysieren und diese zügig in einer Reihe intensiver Sprints ' +
            'gemeinsam mit dem Kunden zu optimieren.'
          "
        />
        <ModalCard
          :ModalImgSrc="require('@/assets/img/growth.svg')"
          :ModalHeadline1="'Ergebnisse'"
          :ModalText="
            'Unsere Arbeit hat zu spürbar effizienteren Prozessen geführt. ' +
            'Gleichzeitig konnten wir die Stabilität der Softwarelandschaft erhöhen und die Qualität ' +
            'der Daten erheblich verbessern.'
          "
        />
        <ModalCard
          :ModalImgSrc="require('@/assets/img/diamond.svg')"
          :ModalHeadline1="'Kundenzufriedenheit'"
          :ModalText="
            'Alle Beteiligten waren äußerst zufrieden mit den Ergebnissen. Die ' +
            'Personalabteilung wurde bei der jüngsten Überprüfung sogar als erste ' +
            'Abteilung auditiert. '
          "
        />
        <ModalCard
          :ModalImgSrc="require('@/assets/img/atom.svg')"
          :ModalHeadline1="'Zusammenfassung'"
          :ModalText="
            'Unser Projekt bei ABUS Kransysteme GmbH hat gezeigt, dass wir ' +
            'komplexe Herausforderungen in den Bereichen Prozessoptimierung und ' +
            'Digitalisierung erfolgreich meistern. Wir freuen uns bereits, auch anderen ' +
            'Kunden bei der Verbesserung ihrer Prozesse zur Seite zu stehen.'
          "
        />
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
import ModalCard from '@/components/tools/ModalCard.vue';

export default {
  components: {
    ModalCard,
  },
};
</script>
<style scoped>
.modal-header-custom {
  border-bottom: none !important;
}
.modal-close {
  margin-top: 1rem;
  color: black;
  background: none;
  border: none;
}
.modal-close:hover {
  cursor: pointer;
}
.modal-title {
  margin-top: 2rem;
  padding-bottom: 1rem;
  font-weight: 700;
  font-size: 1.4rem;
  text-align: center;
}
.kermodal {
  z-index: 124;
}
h3 {
  text-align: center;
}
.modal-content {
  background-color: #d7ddd8 !important;
}
@media (max-width: 768px) {
  .modal-dialog {
    width: 90% !important;
    margin: 0 auto !important;
  }
}
</style>
