<template>
  <b-navbar toggleable="md" ref="headernavbar" class="header-nav">
    <SessionStartBE></SessionStartBE>
    <b-container class="pb-1" fluid>
      <router-link to="/">
        <b-navbar-brand class="d-xl-flex justify-content-md-end">
          <img class="header-image" src="../assets/img/header_logo.svg" alt="?" />
        </b-navbar-brand>
      </router-link>
      <b-navbar-toggle class="navbar-toggler" target="nav-collapse">
        <b-icon icon="list" variant="light"></b-icon>
      </b-navbar-toggle>
      <b-collapse id="nav-collapse" class="d-md-flex justify-content-md-end" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item>
            <router-link class="navbar-header-link" :to="{ path: '/', hash: '#offerings' }">
              Angebote
            </router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link class="navbar-header-link" :to="{ path: '/', hash: '#contact' }">
              Kontakt
            </router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link class="navbar-header-link" :to="{ path: '/joinoursquad' }">
              Karriere
            </router-link>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>
<style scoped>
.header-image {
  width: 13rem;
  height: 2rem;
}
.navbar-header-link {
  color: white;
  opacity: 0.7;
  font-weight: 400;
}
.navbar-header-link:hover {
  color: white;
  font-weight: 400;
  text-decoration: none;
  opacity: 1;
}
.navbar-toggler {
  color: white;
}
.header-nav {
  background-color: black;
  position: sticky;
  top: 0px;
  z-index: 123;
}
.navbar-link a:hover {
  color: white;
  opacity: 1;
}
.navbar-toggler-icon {
  color: white;
}
</style>
<script>
import SessionStartBE from '@/components/codemodules/SessionStartBE.vue';

export default {
  name: 'TheHeader',
  components: {
    SessionStartBE,
  },
};
</script>
