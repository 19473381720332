var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{staticClass:"kermodal",attrs:{"id":"abusmodal","size":"xl","hide-footer":"","scrollable":"","centered":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticStyle:{"width":"100%","margin-top":"-1.3rem"}},[_c('b-row',[_c('b-col',{staticClass:"col-md-1",attrs:{"cols":"12"}}),_c('b-col',{staticClass:"col-md-10 pt-3",attrs:{"cols":"10"}},[_c('h3',[_vm._v("Prozessoptimierung der Personalabteilung "),_c('br'),_vm._v("durch innovative Software")])]),_c('b-col',{staticClass:"col-md-1 d-flex float-right",attrs:{"cols":"1"}},[_c('b-icon',{staticClass:"h2 modal-close",attrs:{"icon":"x-circle"},on:{"click":function($event){return _vm.$bvModal.hide('abusmodal')}}})],1)],1)],1)]},proxy:true}])},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('ModalCard',{attrs:{"ModalImgSrc":require('@/assets/img/goal.svg'),"ModalHeadline1":'Ziel des Projekts',"ModalText":'Das Ziel des Projekts war es, die Prozesse in der' +
          ' Personalabteilung durch Reorganisation der Softwarelandschaft hinsichtlich' +
          ' auf Sicherheit, Redundanz und Effizienz zu optimieren.'}}),_c('ModalCard',{attrs:{"ModalImgSrc":require('@/assets/img/handshake.svg'),"ModalHeadline1":'Kunde und Herausforderungen',"ModalText":'Unser langjähriger Geschäftspartner ist die angesehene Frima ABUS Kransysteme. ' +
          'Die besondere Herausforderung in diesem Projekt war, bei einer dünnen Personaldecke ausreichend ' +
          'Zeit bei den Beteiligten zu finden, um Raum für Lösungen zu schaffen!'}}),_c('ModalCard',{attrs:{"ModalImgSrc":require('@/assets/img/planning.svg'),"ModalHeadline1":'Lösungsansatz',"ModalText":'Unser Ansatz bestand darin, die bestehenden Prozesse im Detail zu' +
          ' analysieren und diese zügig in einer Reihe intensiver Sprints ' +
          'gemeinsam mit dem Kunden zu optimieren.'}}),_c('ModalCard',{attrs:{"ModalImgSrc":require('@/assets/img/growth.svg'),"ModalHeadline1":'Ergebnisse',"ModalText":'Unsere Arbeit hat zu spürbar effizienteren Prozessen geführt. ' +
          'Gleichzeitig konnten wir die Stabilität der Softwarelandschaft erhöhen und die Qualität ' +
          'der Daten erheblich verbessern.'}}),_c('ModalCard',{attrs:{"ModalImgSrc":require('@/assets/img/diamond.svg'),"ModalHeadline1":'Kundenzufriedenheit',"ModalText":'Alle Beteiligten waren äußerst zufrieden mit den Ergebnissen. Die ' +
          'Personalabteilung wurde bei der jüngsten Überprüfung sogar als erste ' +
          'Abteilung auditiert. '}}),_c('ModalCard',{attrs:{"ModalImgSrc":require('@/assets/img/atom.svg'),"ModalHeadline1":'Zusammenfassung',"ModalText":'Unser Projekt bei ABUS Kransysteme GmbH hat gezeigt, dass wir ' +
          'komplexe Herausforderungen in den Bereichen Prozessoptimierung und ' +
          'Digitalisierung erfolgreich meistern. Wir freuen uns bereits, auch anderen ' +
          'Kunden bei der Verbesserung ihrer Prozesse zur Seite zu stehen.'}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }