<template>
  <b-container fluid class="joblist-container">
    <b-row class="mb-3 pt-2">
      <b-col cols="12" class="d-flex justify-content-center">
        <h1 class="text-center text-md-left mb-5 mt-5 h1-media-size joblist-headline">
          Offene Stellen
        </h1>
      </b-col>
      <b-col cols="12">
        <b-row>
          <b-col cols="12" class="col-xxl-2 col-xl-2"></b-col>
          <b-col cols="12" class="col-xxl-8 col-xl-8">
            <p>
              Wir wollen uns auch in Zukunft weiterentwickeln und wachsen. Aus diesem Grund sind wir
              auf der Suche nach jungen Talenten und Professionals in verschiedenen Bereichen, die
              Kundenprojekte gemeinsam mit uns entwickeln und Keravonos auf das nächste Level
              bringen.
              <i class="bi bi-bookmark-star-fill"></i>
            </p> </b-col
        ></b-row>

        <b-row class="mx-0 mt-4">
          <b-col cols="12" class="col-xxl-2 col-xl-2"></b-col>
          <b-col cols="12" class="col-xxl-8 col-xl-8 px-2">
            <b-row class="mx-0">
              <b-col class="px-0">
                <b-card class="kercard py-2">
                  <h4><b>Deine Benefits bei Keravonos</b></h4>
                  <ul class="text-left p-0 pt-3">
                    <li class="mb-1">
                      <b-icon-bookmark-star-fill class="jobad-icon"></b-icon-bookmark-star-fill>
                      Ein junges, motiviertes Team, in dem ein offener Austausch und Kollegialität
                      an oberster Stelle stehen
                    </li>
                    <li class="mb-1">
                      <b-icon-bookmark-star-fill class="jobad-icon"></b-icon-bookmark-star-fill>
                      Interessante und abwechslungsreiche Projekte, bei denen du dich aktiv mit
                      einbringen kannst
                    </li>
                    <li class="mb-1">
                      <b-icon-bookmark-star-fill class="jobad-icon"></b-icon-bookmark-star-fill>
                      Eine offene und transparente Kommunikationskultur
                    </li>
                    <li class="mb-1">
                      <b-icon-bookmark-star-fill class="jobad-icon"></b-icon-bookmark-star-fill>
                      Möglichkeiten zur beruflichen Entfaltung und Weiterentwicklung
                    </li>
                    <li class="mb-1">
                      <b-icon-bookmark-star-fill class="jobad-icon"></b-icon-bookmark-star-fill>
                      Gestaltungsspielraum für eigene Ideen
                    </li>
                    <li class="mb-1">
                      <b-icon-bookmark-star-fill class="jobad-icon"></b-icon-bookmark-star-fill>
                      Flexible Arbeitszeiten
                    </li>
                    <li class="mb-1">
                      <b-icon-bookmark-star-fill class="jobad-icon"></b-icon-bookmark-star-fill>
                      Spannende Team-Events und Mitarbeiter-lncentives
                    </li>
                    <li class="mb-1">
                      <b-icon-bookmark-star-fill class="jobad-icon"></b-icon-bookmark-star-fill> Ein
                      gefüllter Getränke-Kühlschrank und immer genügend Kaffee
                    </li>
                  </ul>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="col-xxl-2 col-xl-2"></b-col>
      <b-col cols="12" class="col-xxl-8 col-xl-8">
        <b-row style="padding-bottom: 5rem">
          <b-col
            cols="12"
            class="offset-lg-0 col-lg-4 mt-4 col-sm-8 offset-sm-2 col-md-6 offset-md-3"
          >
            <KerCard
              :CardIconPath="require('@/assets/img/dev.svg')"
              :CardHeadline="'Web <br/> Developer'"
              :CardText="'Entwickle bei uns im Team moderne Webanwendungen.'"
              :CardButtonText="'Mehr Infos'"
              :CardButtonLink="'webdev'"
            />
          </b-col>
          <b-col
            cols="12"
            class="offset-lg-0 col-lg-4 mt-4 col-sm-8 offset-sm-2 col-md-6 offset-md-3"
          >
            <KerCard
              :CardIconPath="require('@/assets/img/assgf.svg')"
              :CardHeadline="'Ass. der <br/> Geschäftsführung'"
              :CardText="'Arbeite direkt mit der Geschäftsführung zusammen. Gestallte das Unternehmen aktiv mit.'"
              :CardButtonText="'Mehr Infos'"
              :CardButtonLink="'ass'"
            />
          </b-col>
          <b-col
            cols="12"
            class="offset-lg-0 col-lg-4 mt-4 col-sm-8 offset-sm-2 col-md-6 offset-md-3"
          >
            <KerCard
              :CardIconPath="require('@/assets/img/juncon.svg')"
              :CardHeadline="'Junior Consultant <br/> Digitalisierung'"
              :CardText="'Hilf unseren Kunden mittels moderner Technik, Abläufe zu vereinfachen und zu automatisieren.'"
              :CardButtonText="'Mehr Infos'"
              :CardButtonLink="'jucon'"
            />
          </b-col>
          <b-col
            cols="12"
            class="offset-lg-0 col-lg-4 mt-4 col-sm-8 offset-sm-2 col-md-6 offset-md-3"
          >
            <KerCard
              :CardIconPath="require('@/assets/img/dev.svg')"
              :CardHeadline="'Werkstudent <br/> Informatik'"
              :CardText="'Bewirb dich jetzt als Werkstudent und sammle wichtige erste Berufserfahrung.'"
              :CardButtonText="'Mehr Infos'"
              :CardButtonLink="'itstudent'"
            />
          </b-col>
          <b-col
            cols="12"
            class="offset-lg-0 col-lg-4 mt-4 col-sm-8 offset-sm-2 col-md-6 offset-md-3"
          >
            <KerCard
              :CardIconPath="require('@/assets/img/chilldev.svg')"
              :CardHeadline="'Werkstudent <br/> Wirtschaft'"
              :CardText="'Ob du BWL oder Wirtschaftsingenieurwesen studierst. Wir freuen und auf deine Bewerbung!'"
              :CardButtonText="'Mehr Infos'"
              :CardButtonLink="'ecostudent'"
            />
          </b-col>
          <b-col
            cols="12"
            class="offset-lg-0 col-lg-4 mt-4 col-sm-8 offset-sm-2 col-md-6 offset-md-3"
          >
            <KerCard
              :CardIconPath="require('@/assets/img/search.svg')"
              :CardHeadline="'Initiativbewerbung'"
              :CardText="
                'Nichts dabei, aber trotzdem motiviert? Wir freuen uns immer' +
                ' über aussagekräftige Initiativbewerbungen.'
              "
              :CardButtonText="'Jetzt bewerben'"
              :CardButtonLink="'initiative'"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import KerCard from '@/components/tools/KerCard.vue';

export default {
  components: {
    KerCard,
  },
};
</script>
<style scoped>
.jobad-icon {
  margin-right: 0.5rem;
  color: #2b572e;
}
.joblist-container {
  background: #d7ddd8;
}
.joblist-headline {
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.2;
}
ul {
  list-style: none;
  padding-left: 0;
}
</style>
