<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12" class="d-flex justify-content-center align-items-center">
        <dropzone
          ref="myDropzone"
          :options="dropzoneOptions"
          :id="'application-dropzone'"
          @vdropzone-file-added="sendFiles"
        ></dropzone>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="12" class="col-md-4"></b-col>
      <b-col cols="12" class="col-md-4"> </b-col>
    </b-row>
  </b-container>
</template>
<script>
import Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  components: {
    dropzone: Dropzone,
  },
  data() {
    return {
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        addRemoveLinks: true,
        maxFilesize: 5262785,
        acceptedFiles: '.pdf',
        maxFiles: 5,
        dictDefaultMessage: 'PDF per drag & drop hier ablegen.',
        dictCancelUpload: 'Abbrechen',
        dictFallbackMessage:
          'Ihr Browser unterstützt das Hochladen von Dateien per Drag & Drop nicht.',
        dictFallbackText: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
        dictFileTooBig: 'Die Datei überschreitet die maximale Dateigröße von 5 MB.',
        dictInvalidFileType: 'Sie können keine Dateien dieses Typs hochladen.',
        dictResponseError: 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.',
        dictUploadCanceled: 'Hochladen abgebrochen.',
        dictCancelUploadConfirmation: 'Sind Sie sicher, dass Sie das Hochladen abbrechen möchten?',
        dictRemoveFile: 'Löschen',
        dictMaxFilesExceeded: 'Sie können keine weiteren Dateien hochladen.',
      },
      emailResponse: '',
      uploadedFiles: [],
    };
  },
  mounted() {
    this.$refs.myDropzone.dropzone.on('addedfile', (file) => {
      this.uploadedFiles.push(file);
      // eslint-disable-next-line no-param-reassign
      file.previewElement.querySelector('.dz-progress').style.display = 'none';
    });
  },
  methods: {
    sendFiles(file) {
      this.$emit('getApplicationFiles', file);
      const pdfIcon = document.createElement('div');
      pdfIcon.classList.add('pdf-icon');

      file.previewElement.appendChild(pdfIcon);

      const successMark = file.previewElement.querySelector('[data-dz-successmark]');
      successMark.style.display = 'none';
      successMark.style.opacity = '1';
    },
  },
};
</script>

<style>
ul {
  /* list-style-type: none; */
}
.pdf-icon {
  background-image: url('../../assets/img/pdf-icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  z-index: 10;
  border: none !important;
  color: #00ff00 important;
}

.dz-details {
  background-color: #00ff00;
  border-width: 0.1rem;
  border-radius: 15px;
  z-index: 0 !important;
}
.dz-size {
  display: none !important;
}
.dz-filename {
  bottom: 5px;
  position: absolute;
  overflow: ellipsis !important;
  width: 100%;
  display: inline-block;
  left: 0;
  display: inline-block;
  text-align: center;
}
.dz-filename > span {
  width: 100%;
}

.dropzone .dz-preview .dz-remove:hover {
  text-decoration: none;
}

.dz-progress {
  background-color: #00ff00 !important;
}

.dz-remove {
  text-align: center;
  opacity: 1 !important;
  border: none !important;
  position: absolute !important;
  top: 0px !important;
  right: 0 !important;
}

.dz-remove a:hover {
  text-decoration: none;
}

.vue-dropzone {
  background-color: #151616;
  border-style: dashed;
  font-size: 1.2rem;
  font-weight: 700;
  min-height: 6rem;
  width: 100%;
}
.dz-error-message {
  margin-top: 3rem;
  padding: 1rem;
  min-width: 10rem;
}

.dz-error-mark {
  background-color: red;
  border-radius: 25px !important;
  width: auto !important;
  margin-left: 1.25rem !important;
  margin-top: -0.465rem !important;
  padding: 0.04rem !important;
}

.vue-dropzone:hover {
  background-color: #151616;
}
.dz-image {
  background: none !important;
}

.dz-image > img {
  visibility: hidden;
  width: 100px !important;
  height: 130px !important;
}

.dz-preview .dz-details {
  border-style: none !important;
  border-color: #00ff00;
  background-color: transparent !important;
}
.apply-button {
  color: #00ff00 !important;
  background-color: #37563c !important;
  border-color: #00ff00 !important;
  border-style: solid !important;
  border-width: 2px !important;
  border-radius: 25px !important;
  padding-bottom: 2.5rem !important;
}
.apply-button:hover {
  color: #00ff00 !important;
  background-color: #58815f !important;
  border-color: #00ff00 !important;
}

.vue-dropzone > .dz-preview {
  padding: 0 !important;
}
</style>
