<template>
  <b-row v-show="currentStep === stepid" class="stepper-content pb-4 pt-4">
    <b-col cols="12"></b-col>
    <b-col cols="12" class="pt-4">
      <b-row>
        <b-col cols="12" class="col-md-1"></b-col>
        <b-col cols="12" class="col-md-10 pt-3">
          <h3 class="pt-3 application-text-headline">{{ headline }}</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="col-md-3"></b-col>
        <b-col cols="12" class="col-md-6 pt-3 application-text">
          <p class="pb-4">{{ applicationtext }}</p>
        <slot></slot>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>

export default {
  props: {
    currentStep: Number,
    stepid: Number,
    headline: String,
    applicationtext: String,
  },
};
</script>
<style>
.stepper-content {
  display: none;
}
</style>
