<template>
  <b-container class="aboutus-container" fluid>
    <b-row class="aboutus_content">
      <b-col cols="1" class="col-xl-2"></b-col>
      <b-col cols="10" class="col-md-3 col-lg-4 aboutus-text pt-3">
        <h1 class="text-left text-md-left mb-5 mt-5 h1-media-size aboutus-headline">Über uns</h1>
        <p class="pb-4">
          Seit einigen Jahren sind wir intensiv im Bereich der Digitalisierung engagiert und haben
          dabei eine Leidenschaft für das Kreieren neuer Lösungen entwickelt. Vom digitalen
          Fahrradverleih bis hin zur Buchungssoftware setzen wir gerne gemeinsam mit unseren Kunden
          deren Wünsche um.
        </p>
        <h4 class="pt-4"><b>Werde Teil unseres Teams</b></h4>
        <p>
          Lust, die Digitalisierung unserer Kunden aktiv mitzugestalten und an spannenden Projekten
          teilzuhaben? Dann schau dir unsere offenen Stellen an.
        </p>
        <router-link class="navbar-header-link" :to="{ path: '/joinoursquad' }">
          <b-row>
            <b-col cols="12" class="text-center text-md-left">
              <b-button class="button-default mt-4 mb-4" type="button">Kontakt aufnehmen </b-button>
            </b-col>
          </b-row>
        </router-link>
      </b-col>
      <b-col
        cols="12"
        class="mt-3 col-md-7 col-lg-6 col-xl-5 d-flex justify-content-end justify-content-xl-start"
      >
        <img src="../assets/img/section5_img.svg" alt="?" class="aboutus-img" />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="10" offset-md="1">
        <InstagrammEmbedElfsight />
      </b-col>
    </b-row>
    <b-row class="pt-5">
      <b-col cols="12" class="d-flex justify-content-center">
        <h1 class="aboutus-headline text-center text-md-left mb-5 mt-5 h1-media-size">
          Referenzen
        </h1>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
// import InstagrammEmbed from './InstagrammEmbed.vue';
import InstagrammEmbedElfsight from './InstagrammEmbedElfsight.vue';

export default {
  name: 'AboutusHome',
  components: {
    // InstagrammEmbed,
    InstagrammEmbedElfsight,
  },
};
</script>
<style>
.aboutus-container {
  background: #d7ddd8;
  margin-top: -9rem;
}
.aboutus_content {
  padding-top: 8rem;
}
.aboutus-text {
  text-align: left;
}
.aboutus-headline {
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.2;
}
.aboutus-img {
  max-width: 90%;
}
p {
  line-height: 1.7rem;
}
</style>
