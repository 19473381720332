var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{class:[
    _vm.ModalID !== '' ? 'ref-img' : '',
    'col-md-4',
    'd-flex',
    'justify-content-center',
    'align-items-center',
    'block'
  ],style:('background: linear-gradient(1deg, rgba(0,0,0,0.8) 0%, rgba(4,4,4,0.9) 54%, rgba(0,0,0,0.3) 100%), url(' +
    _vm.BlockBgPath +
    ')'),attrs:{"cols":"12"},on:{"click":function($event){return _vm.$bvModal.show(_vm.ModalID)}}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{staticClass:"col-md-1",attrs:{"cols":"12"}}),_c('b-col',{staticClass:"col-md-10",attrs:{"cols":"12"}},[_c('img',{attrs:{"alt":"","src":_vm.BlockImage}})]),_c('b-col',{staticClass:"block-caption mt-3",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.BlockCaption)+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }