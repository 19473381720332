<template>
  <b-container class="jobad-quote-container align-items-center justify-content-center d-flex" fluid>
    <b-row class="pt-4">
      <b-col cols="1" class="col-md-2"></b-col>
      <b-col cols="10" class="col-md-8">
        <b-row>
          <b-col cols="12" class="col-md-6 jobad-quote-text pt-3 order-2 order-md-1">
            <b-row class="d-flex justify-content-center">
              <b-col cols="11" class="col-md-9 pt-3 quote-text">
                <p>
                  {{ quoteText }}
                </p>
              </b-col>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <b-col class="pt-3">
                <p class="jobad-quote-text2">
                  <b>{{ quoteName }}</b
                  ><br />
                  {{ quoteDate }}
                </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="col-md-6 order-1 order-md-2">
            <img alt="?" class="jobad-quote-img" :src="quoteImage" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  props: {
    quoteImage: String,
    quoteText: String,
    quoteName: String,
    quoteDate: String,
  },
};
</script>
<style scoped>
.jobad-quote-container {
  background-image: url('@/assets/img/quote_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  min-height: 100vh;
}
.jobad-quote-text {
  color: #a3a3a3;
}
.jobad-quote-text2 {
  color: white;
}
.jobad-quote-img {
  height: 18rem;
}
</style>
