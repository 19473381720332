<template>
  <b-card class="kercard" v-on="$listeners">
    <b-row>
      <b-col cols="12" class="p-2 d-flex justify-content-center">
        <img alt="?" class="kercard-image" :src="CardIconPath" />
      </b-col>
      <b-col cols="12" class="mt-4">
        <h4 class="title-height" v-html="CardHeadline"></h4>
        <p class="text-center kercard-text mt-1">{{ CardText }}</p>
      </b-col>
      <b-col cols="12" class="d-flex justify-content-center mt-2">
        <router-link class="navbar-header-link" :to="{ path: CardButtonLink, hash: CardHash }">
          <b-button class="button-default mb-3">
            {{ CardButtonText }}
          </b-button></router-link
        >
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
export default {
  props: {
    CardIconPath: String,
    CardHash: String,
    CardHeadline: String,
    CardText: String,
    CardButtonText: String,
    CardButtonLink: String,
  },
};
</script>
<style scoped>
.title-height {
  line-height: 2rem;
  height: 5rem;
}
.modal-button {
  color: #fff;
  background-color: #37563c !important;
  border-color: #37563c !important;
  border-style: none !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  font-size: 1rem !important;
  border-radius: 3rem !important;
}
.kercard {
  min-height: 16rem;
}
.kercard-text {
  height: 9rem;
  text-overflow: ellipsis;
  overflow: hidden;
}
.kercard-image {
  height: 5rem;
}
h4 {
  font-weight: 700;
}
p {
  line-height: 1.8rem;
}
</style>
