<!-- eslint-disable max-len -->
<template>
  <b-container fluid>
    <b-row id="mobile-img-intro" class="bg-color-dark-grey">
      <b-col cols="12" class="bg-mobile-intro" :style="'background:  url(' + IntroBg + ')'"></b-col>
    </b-row>
    <b-row class="intro bg-color-dark-grey">
      <b-col
        cols="12"
        class="intro_left col-md-5 col-lg-4 offset-lg-1 offset-xl-1 d-flex d-xl-flex justify-content-left align-items-center align-content-center flex-wrap col-alignment-media-screen"
      >
        <h1 class="intro-headline flex mb-5">{{ IntroHeadline }}</h1>
        <p class="mt-3">
          {{ IntroText }}
        </p>
        <router-link class="navbar-header-link" :to="{ hash: '#stepper2' }">
          <b-button :id="IntroButtonType" class="button-default mt-4" type="button">
            {{ IntroButton }}
          </b-button></router-link
        >
      </b-col>
      <b-col
        cols="6"
        class="col-md-7 col-lg-7 bg-fullscreen-intro"
        :style="'background:  url(' + IntroBg + ')'"
      >
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  props: {
    IntroHeadline: String,
    IntroBg: String,
    IntroText: String,
    IntroButton: String,
    IntroButtonType: String,
  },
};
</script>
<style scoped>
#Intro-Button-2 {
  color: #00ff00 !important;
  background-color: #37563c !important;
  border-color: #00ff00 !important;
  border-style: solid !important;
  border-width: 2px !important;
}
.intro {
  color: white;
  height: 80vh;
  max-height: 1669px;
  text-align: left;
  line-height: 1.7rem;
}
.intro-headline {
  text-align: left;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.2;
}
#mobile-img-intro {
  display: none;
}
.bg-mobile-intro {
  height: 30vh;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.bg-fullscreen-intro {
  background-position: right !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
@media (max-width: 768px) {
  #mobile-img-intro {
    display: block;
  }
  .bg-fullscreen-intro {
    display: none;
  }
  .h1 {
    font-size: 1.5rem;
  }
  .intro {
    height: 80vh;
  }
}
</style>
