<script>
/* eslint-disable */
</script>
<template>
  <div class="cookie-banner">
    <cookie-consent>
      <template slot="message">
        <div class="row mt-2">
          <div class="col-lg-3 col-sm-1"></div>
          <div class="col-lg-6 px-4 p-2">
            {{ cookieText }}
            <a class="text-black" href="/datenschutz"><u>KERAVONOS Datenschutzerklärung</u></a>
          </div>
          <div class="col-lg-3 col-sm-1"></div>
        </div>
        <!-- <div class="row">
            <div class="col-4"></div>
            <div class="col-4 d-flex justify-content-center align-items-center">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  v-model="functionalCookie"
                  checked
                />
                <label class="form-check-label">Funktional</label>
              </div>
            </div>
            <div class="col-4"></div>
          </div> -->
        <div class="row">
          <div class="col-2 col-xl-4"></div>
          <div class="col-8 col-xl-4">
            <div>
              <b-button
                v-b-toggle.collapse-1
                class="cookie-collapse-button"
                @click="cookieAccordionCollapseStatus = !cookieAccordionCollapseStatus"
                >Cookie Einstellungen</b-button
              >
              <b-collapse id="collapse-1" v-show="cookieAccordionCollapseStatus">
                <b-card class="cookie-collapse-body mt-2">
                  <div class="accordion-body">
                    <div class="row">
                      <div class="col-4"></div>
                      <div class="col-4 d-flex justify-content-center align-items-center">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked
                            disabled
                          />
                          <label class="form-check-label text-black">Essenziell</label>
                        </div>
                      </div>
                      <div class="col-4"></div>
                    </div>
                    <div class="row">
                      <div class="col-4"></div>
                      <div class="col-4 d-flex justify-content-center align-items-center">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            v-model="marketingCookie"
                            checked
                          />
                          <label class="form-check-label" for="flexSwitchCheckChecked"
                            >Marketing
                          </label>
                        </div>
                      </div>
                      <div class="col-4"></div>
                    </div>
                  </div>
                </b-card>
              </b-collapse>
            </div>
            <div class="row mt-2">
              <div class="col-lg-2 col-xl-3"></div>
              <div class="col-lg-6 col-xl-6 p-1"></div>
              <div class="col-lg-2 col-xl-3"></div>
            </div>
          </div>
          <div class="col-4"></div>
        </div>
        <!-- <div class="row">
            <div class="col-4"></div>
            <div class="col-4 d-flex justify-content-center align-items-center">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  v-model="statisticCookie"
                  checked
                />
                <label class="form-check-label" for="flexSwitchCheckChecked">Statistiken</label>
              </div>
            </div>
            <div class="col-4"></div>
          </div> -->
      </template>
      <template slot="button">
        <div class="row mb-1">
          <div class="col-lg-2 col-xl-3"></div>
          <div class="col-lg-6 col-xl-6 p-1">
            <button
              v-if="cookieAccordionCollapseStatus"
              @click="setAllowedCookies()"
              class="button-cookie"
            >
              Auswahl akzeptieren
            </button>
          </div>
          <div class="col-lg-2 col-xl-3"></div>
        </div>
        <div class="row mt-2 mb-4">
          <div class="col-lg-4 col-xl-4"></div>
          <div class="col-lg-4 col-xl-4 p-1">
            <button @click="setAllCookies()" class="button-small">Alle Cookies akzeptieren</button>
          </div>
          <!-- <div class="col-lg-4 col-xl-3 p-1">
            <button @click="setAllowedCookies()" class="button-cookie">Auswahl akzeptieren</button>
          </div> -->
          <div class="col-lg-4 col-xl-4"></div>
        </div>
      </template>
    </cookie-consent>
  </div>
</template>

<style lang="scss">
.cookie-collapse-button {
  background-color: grey !important;
  border: none !important;
  border-radius: 32px !important;
}
.cookie-collapse-body {
  background-color: transparent !important;
  border: none !important;
}
.accordion-text {
  width: 100%;
  text-align: center;
}
.accordion-button {
  background-color: lightgrey !important;
  height: 2.3rem;
  border-radius: 2px !important;
  color: black !important;
  box-shadow: none !important;
}
.cookie-banner {
  width: 100%;
  left: 0%;
  background-color: #8f8f8feb;
  z-index: 1;
  position: fixed;
  bottom: 0px;
}
.form-check-input:checked {
  background-color: #37563c;
  border-color: #37563c;
  border-radius: 2px;
}

.cookie-accordion-header {
  background-color: #37563c;
  border-color: #37563c;
  border-radius: 2px;
}
</style>

<script>
import { getGtagJs } from '@/external/gtag';
import { getMetaPCJs } from '@/external/MetaPC';
import { getLinkedTagJs } from '@/external/linkedintag';
import CookieConsent from 'vue-cookieconsent-component';

export default {
  components: {
    CookieConsent,
  },
  data() {
    return {
      functionalCookie: false,
      marketingCookie: false,
      statisticCookie: false,
      functionalCookieValue: 'kerFuncCoo=1; max-age=31536000; path=/',
      marketingCookieValue: 'kerMarCoo=1; max-age=31536000; path=/',
      statisticCookieValue: 'kerStatCoo=1; max-age=31536000; path=/',
      cookieText: `Wir nutzen Cookies, um das Besuchererlebnis unserer Webseite stetig zu verbessern. Alle
      Informationen zu unseren Cookie-Einstellungen und Datenschutz siehe`,
      cookieAccordionCollapseStatus: false,
    };
  },
  methods: {
    setMarketingCookies() {
      getGtagJs();
      getMetaPCJs();
      getLinkedTagJs();
    },
    setFunctionalCookies() {
      null;
    },
    setStatisticCookies() {
      null;
    },
    setAllowedCookies() {
      if (this.marketingCookie) {
        this.setCookie(this.marketingCookieValue);
        this.setMarketingCookies();
      }
      if (this.functionalCookie) {
        this.setCookie(this.functionalCookieValue);
        this.setFunctionalCookies();
      }
      if (this.statisticCookie) {
        this.setCookie(this.statisticCookieValue);
        this.setStatisticCookies();
      }
    },
    setAllCookies() {
      this.setCookie(this.marketingCookieValue);
      this.setMarketingCookies();

      this.setCookie(this.functionalCookieValue);
      this.setFunctionalCookies();

      this.setCookie(this.statisticCookieValue);
      this.setStatisticCookies();
    },
    setCookie(cookieValue) {
      document.cookie = cookieValue;
    },
    readCookie(cookieName) {
      const cookieValue = document.cookie.replace(
        new RegExp('(?:(?:^|.*;\\s*)' + cookieName + '\\s*\\=\\s*([^;]*).*$)|^.*$'),
        '$1'
      );
      return cookieValue;
    },
  },
  mounted() {
    const kerMarCoo = this.readCookie('kerMarCoo');
    if (kerMarCoo === '1') {
      this.setMarketingCookies();
    }
    const kerFuncCoo = this.readCookie('kerFuncCoo');
    if (kerFuncCoo === '1') {
      this.setFunctionalCookies();
    }
    const kerStatCoo = this.readCookie('kerStatCoo');
    if (kerStatCoo === '1') {
      this.setStatisticCookies();
    }
  },
};
</script>
