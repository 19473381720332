<!-- eslint-disable max-len -->
<template>
  <b-container fluid class="offerings-container" id="offerings">
    <b-row class="mb-3 pt-2">
      <b-col cols="12" class="d-flex justify-content-center">
        <h1 class="text-center text-md-left mb-5 mt-5 h1-media-size offerings-headline">
          Unsere Angebote
        </h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="col-xxl-2 col-xl-2"></b-col>
      <b-col cols="12" class="col-xxl-8 col-xl-8">
        <b-row style="padding-bottom: 15rem">
          <b-col
            cols="12"
            class="offset-lg-0 col-lg-4 mt-4 col-sm-8 offset-sm-2 col-md-6 offset-md-3"
          >
            <KerCard
              :CardIconPath="require('../assets/img/WebdevIcon.svg')"
              :CardHeadline="'Webentwicklung'"
              :CardText="
                'Vom Prototyp bis zur fertigen Applikation. ' +
                'Unsere Leidenschaft sind Anwendungen, ' +
                'die einen direkten Mehrwert bieten.'
              "
              :CardButtonText="'Kontakt aufnehmen'"
              :CardButtonLink="''"
              :CardHash="'#contact'"
              @mouseover.native="handleMouseOver('webdev')"
              @mouseleave.native="handleMouseLeave"
              @focus.native="handleMouseOver('webdev')"
              @blur.native="handleMouseLeave"
              :class="{ hover: hoveredCard === 'webdev' }"
            />
          </b-col>
          <b-col
            cols="12"
            class="offset-lg-0 col-lg-4 mt-4 col-sm-8 offset-sm-2 col-md-6 offset-md-3"
          >
            <KerCard
              :CardIconPath="require('../assets/img/WorkshopIcon.svg')"
              :CardHeadline="'Beratung'"
              :CardText="'Prozessoptimierung, App-Design, Strategie – Wir machen Ihre Fragen zu unseren und meistern gemeinsam die digitale Transformation.'"
              :CardButtonText="'Kontakt aufnehmen'"
              :CardButtonLink="''"
              :CardHash="'#contact'"
              @mouseover.native="handleMouseOver('consulting')"
              @mouseleave.native="handleMouseLeave"
              @focus.native="handleMouseOver('consulting')"
              @blur.native="handleMouseLeave"
              :class="{ hover: hoveredCard === 'consulting' }"
            />
          </b-col>
          <b-col
            cols="12"
            class="offset-lg-0 col-lg-4 mt-4 col-sm-8 offset-sm-2 col-md-6 offset-md-3"
          >
            <KerCard
              :CardIconPath="require('../assets/img/TeamingIcon.svg')"
              :CardHeadline="'Teaming'"
              :CardText="
                'Sowohl kurzfristig als auch langfristig unterstützen wir gerne Ihr ' +
                'Entwicklungsteam.  Es gibt auch die Möglichkeit ' +
                'der Vermittlung unserer Mitarbeiter.'
              "
              :CardButtonText="'Kontakt aufnehmen'"
              :CardButtonLink="''"
              :CardHash="'#contact'"
              @mouseover.native="handleMouseOver('teaming')"
              @mouseleave.native="handleMouseLeave"
              @focus.native="handleMouseOver('teaming')"
              @blur.native="handleMouseLeave"
              :class="{ hover: hoveredCard === 'teaming' }"
            />
          </b-col>
          <b-col
            cols="12"
            class="mt-5 col-lg-12 offset-lg-0 col-lg-4 mt-4 col-sm-8 offset-sm-2 col-md-6 offset-md-3"
          >
            <KerAsanaCard
              :CardHeadline="'Wir sind Asana-Solutionspartner!'"
              :CardButtonText="'Kontakt aufnehmen'"
              :CardButtonLink="''"
              :CardHash="'#contact'"
              @mouseover.native="handleMouseOver('asana')"
              @mouseleave.native="handleMouseLeave"
              @focus.native="handleMouseOver('asana')"
              @blur.native="handleMouseLeave"
              :class="{ hover: hoveredCard === 'asana' }"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import KerCard from '@/components/tools/KerCard.vue';
import KerAsanaCard from '@/components/tools/KerAsanaCard.vue';

export default {
  components: {
    KerCard,
    KerAsanaCard,
  },
  data() {
    return { hoveredCard: null };
  },
  methods: {
    handleMouseOver(cardName) {
      this.hoveredCard = cardName;
    },
    handleMouseLeave() {
      this.hoveredCard = null;
    },
  },
};
</script>
<style scoped>
.hover {
  /* background-color: #eb1a1a; Ändern Sie die Hintergrundfarbe der Karte beim Überfahren mit der Maus */
  transform: scale(1.07);
  transition: transform 0.4s ease; /* Fügen Sie eine sanfte Übergangsanimation hinzu */
}
.kercard {
  min-height: 16rem;
  transition: transform 0.4s ease; /* Fügen Sie eine sanfte Übergangsanimation hinzu */
}
.offerings-container {
  background: #d7ddd8;
}
.offerings-headline {
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.2;
}
</style>
