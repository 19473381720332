const devmode = false;
// eslint-disable-next-line import/no-mutable-exports
let SERVERPATH = '/';
if (devmode) {
  SERVERPATH = 'http://127.0.0.1:5000/';
}

const KER_MAILER_SERVICE_URL = 'https://mail.keravonos.com/send_email';

export default { SERVERPATH, KER_MAILER_SERVICE_URL };
