<!-- eslint-disable max-len -->
<template>
  <div class="webdev">
    <IntroComponent
      :IntroHeadline="'Assistenz der Geschäftsführung'"
      :IntroBg="require('../assets/img/jucon_cut_longer.png')"
      :IntroText="
        'In direkter Zusammenarbeit mit der Geschäftsführung übernimmst du ab' +
        ' dem ersten Tag Verantwortung und gestaltest die Zukunft von KERAVONOS maßgeblich mit.' +
        ' Gemeinsam entwickeln wir unsere Buchungssoftware weiter, automatisieren Workflows und' +
        ' implementieren beständig neue Web-Apps.'
      "
      :IntroButton="'Jetzt bewerben'"
      :IntroButtonType="'Intro-Button-2'"
    />
    <JobadTasks :tasks="tasks" :profiles="profiles" />
    <JobadBenefits />
    <JobadQuote
      :quoteImage="require('../assets/img/profil_rainer.png')"
      :quoteText="'„Ich freue mich riesig darauf, gemeinsam mit dir die Zukunft von KERAVONOS zu gestalten!“'"
      :quoteName="'Rainer'"
      :quoteDate="'Gründer'"
    />
    <ApplicationAss />
  </div>
</template>
<script>
import IntroComponent from '@/components/tools/IntroComponent.vue';
import JobadTasks from '@/components/tools/JobadTasks.vue';
import JobadBenefits from '@/components/tools/JobadBenefits.vue';
import JobadQuote from '@/components/tools/JobadQuote.vue';
import ApplicationAss from '@/components/tools/ApplicationAss.vue';

export default {
  components: {
    IntroComponent,
    JobadTasks,
    JobadBenefits,
    JobadQuote,
    ApplicationAss,
  },
  data() {
    return {
      tasks: [
        {
          task: 'Unterstützung der Geschäftsführung in allen möglichen Belangen.',
        },
        {
          task:
            'Von der administrativen Unterstützung bis zu konkreten ' +
            'Aufgaben im Kundengeschäft gibt es ein breites Spektrum an Aufgaben.',
        },
      ],
      profiles: [
        {
          profile: 'Idealerweise erste Praxiserfahrung als Assistenz der Geschäftsführung oder in der Projektarbeit',
        },
        { profile: 'Ausgeprägte IT-Affinität und Neugier für neue Methoden' },
        {
          profile: 'Interesse an der direkten Beratung auf Abteilungs- wie Geschäftsleitungsebene',
        },
        {
          profile: 'Agiles Mindset, Empathie- & Kommunikationsfähigkeit sowie ein ausgeprägter Teamgeist',
        },
        { profile: 'Verhandlungssichere Deutsch- und Englischkenntnisse und die nötige Reisebereitschaft.' },
      ],
    };
  },
};
</script>
